import React from "react";
import ContactUs from "./ContactUs";
import Location from "./Location";
import JobForm from "../careers/JobForm";

function Contact() {
  return (
    <>
      {/* <JobForm /> */}
      <ContactUs />
      <Location />
    </>
  );
}

export default Contact;
